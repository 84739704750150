<template>
  <div class="info-page">
    <label style="font-weight:bold;font-size:20px;display:block;margin-bottom:15px;" class="label">Info</label>
    <p><b>Created</b> {{formatDate(item.created_at)}}</p>
    <p><b>Updated</b> {{formatDate(item.updated_at)}}</p>
    <p><b>Version</b> {{landing.version}}</p>
    <p><b>Tariff</b> {{tariff}}</p>
    <p><b>PWA</b> {{landing.advanced.global.pwa?'Enabled':'Disabled'}}</p>
    <p><b>Weather widget</b> {{landing.advanced.global.show_weather?'Enabled':'Disabled'}}</p>
    <p><b>Project ID</b> {{$route.params.id}}</p>
    <p><b>Link to landing: </b> <a rel="noopener noreferrer" :href="`https://guest.eco/${$route.params.id}`" target="_blank">https://guest.eco/{{$route.params.id}}</a></p>
    <div style="display: flex;align-items: center;"><b>Selected languages: </b>
      <div v-for="(lang,index) in landing.selected_langs" :key="lang" style="margin-left: 15px;display: flex;align-items: center;">
          <img
              :src="landing.advanced.langs.find((x) => x.value === lang).flag"
              alt=""
              style="width: 20px; height: 15px; margin-right: 5px"
          />
      <div>
        {{ lang.toUpperCase() }}
        <span v-if="index!==landing.selected_langs.length-1">,</span>
      </div>
      </div>
    </div>
  </div>
</template>

<script>
import {mapState} from "vuex";
export default {
  name: "InfoPage",
  computed:{
    ...mapState(['landing']),
    ...mapState('objects',['item']),
    VUE_APP_LINK(){
      return process.env.VUE_APP_LINK
    },
    tariff(){
      if(this.landing.free){
        return 'FREE'
      }else if(this.landing.profi){
        return 'PROFI'
      }else{
        return "STANDART"
      }
    },
  },
  methods: {
    changeFormatLangPic(lang) {
      const langArr = lang.split("-");
      if (langArr[1]) {
        const candidate = this.countries.find(
            (x) => x.value === langArr[1].toUpperCase()
        );
        if (candidate) {
          return candidate.value;
        }
      } else {
        return lang.toUpperCase();
      }
    },
    formatDate(date){
      return this.$moment(date).format('DD.MM.YYYY HH:ss')
    }
  },
  mounted() {

  }
}
</script>

<style scoped lang="scss">

</style>
